import { useImperativeHandle, forwardRef, useState } from "react";
import { useSpring, animated } from "@react-spring/web";

import {
  STORAGE_DT_ADMIN,
  getJSONStorage,
  removeStorage,
} from "../utilities/Storage";

import ic_open from "../images/next_a.png";
import ic_logout from "../images/logout_a.png";

const FILTER_WIDTH = 250;

export const PageNavigation = forwardRef((props, ref) => {
  const [show_nav, setNav] = useState(false);
  const [ctn_style, ctn_anim] = useSpring(() => ({ width: 0 }), []);

  useImperativeHandle(ref, () => ({
    openNavigation() {
      let c_sts = !show_nav;
      if (c_sts) {
        ctn_anim.start({
          to: [{ width: FILTER_WIDTH }],
        });
      } else {
        ctn_anim.start({
          to: [{ width: 0 }],
        });
      }
      setNav(c_sts);
    },
  }));

  return (
    <animated.div style={{ ...ctn_style, ...{ overflow: "hidden" } }}>
      <div className="brd_rg_ntv_a" style={{ height: "100%" }}>
        {/* <div
          className="btn_hvr_gry"
          style={{
            height: 45,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0 20px",
          }}
        >
          <div style={{ flex: 1 }}>
            <a href="/product" className="item">
              <span className="single_line txt_16 color_gry_a">
                Product Page
              </span>
            </a>
          </div>
          <img alt="" style={{ width: 10 }} src={ic_open} />
        </div> */}

        {/* Absen Page */}
        <div
          className="btn_hvr_gry"
          style={{
            height: 45,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0 20px",
          }}
        >
          <div style={{ flex: 1 }}>
            <a href="/absen" className="item">
              <span className="single_line txt_16 color_gry_a">Absen</span>
            </a>
          </div>
          <img alt="" style={{ width: 10 }} src={ic_open} />
        </div>

        {/* Point Exchange */}
        <div
          className="btn_hvr_gry"
          style={{
            height: 45,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0 20px",
          }}
        >
          <div style={{ flex: 1 }}>
            <a href="/point-exchange" className="item">
              <span className="single_line txt_16 color_gry_a">Manage OTP</span>
            </a>
          </div>
          <img alt="" style={{ width: 10 }} src={ic_open} />
        </div>

        {/* Salesman */}
        <div
          className="btn_hvr_gry"
          style={{
            height: 45,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0 20px",
          }}
        >
          <div style={{ flex: 1 }}>
            <a href="/salesman" className="item">
              <span className="single_line txt_16 color_gry_a">SPG</span>
            </a>
          </div>
          <img alt="" style={{ width: 10 }} src={ic_open} />
        </div>

        {/* Point Mutation User */}
        <div
          className="btn_hvr_gry"
          style={{
            height: 45,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0 20px",
          }}
        >
          <div style={{ flex: 1 }}>
            <a href="/mutasi-point" className="item">
              <span className="single_line txt_16 color_gry_a">
                Mutasi Point
              </span>
            </a>
          </div>
          <img alt="" style={{ width: 10 }} src={ic_open} />
        </div>

        {/* Member */}
        <div
          className="btn_hvr_gry"
          style={{
            height: 45,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0 20px",
          }}
        >
          <div style={{ flex: 1 }}>
            <a href="/member" className="item">
              <span className="single_line txt_16 color_gry_a">Freelancer</span>
            </a>
          </div>
          <img alt="" style={{ width: 10 }} src={ic_open} />
        </div>

        {/* Grosir */}
        <div
          className="btn_hvr_gry"
          style={{
            height: 45,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0 20px",
          }}
        >
          <div style={{ flex: 1 }}>
            <a href="/grosir" className="item">
              <span className="single_line txt_16 color_gry_a">Grosir</span>
            </a>
          </div>
          <img alt="" style={{ width: 10 }} src={ic_open} />
        </div>

        {/* Hadiah */}
        <div
          className="btn_hvr_gry"
          style={{
            height: 45,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0 20px",
          }}
        >
          <div style={{ flex: 1 }}>
            <a href="/hadiah" className="item">
              <span className="single_line txt_16 color_gry_a">Hadiah</span>
            </a>
          </div>
          <img alt="" style={{ width: 10 }} src={ic_open} />
        </div>

        {/* Booking */}
        <div
          className="btn_hvr_gry"
          style={{
            height: 45,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0 20px",
          }}
        >
          <div style={{ flex: 1 }}>
            <a href="/booking" className="item">
              <span className="single_line txt_16 color_gry_a">Booking</span>
            </a>
          </div>
          <img alt="" style={{ width: 10 }} src={ic_open} />
        </div>

        {/* Transaksi */}

        <div
          className="btn_hvr_gry"
          style={{
            height: 45,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0 20px",
          }}
        >
          <div style={{ flex: 1 }}>
            <a href="/transaksi" className="item">
              <span className="single_line txt_16 color_gry_a">Transaksi</span>
            </a>
          </div>
          <img alt="" style={{ width: 10 }} src={ic_open} />
        </div>

        {/* Exchange */}
        <div
          className="btn_hvr_gry"
          style={{
            height: 45,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0 20px",
          }}
        >
          <div style={{ flex: 1 }}>
            <a href="/penukaran" className="item">
              <span className="single_line txt_16 color_gry_a">Penukaran</span>
            </a>
          </div>
          <img alt="" style={{ width: 10 }} src={ic_open} />
        </div>

        {/* Product */}
        <div
          className="btn_hvr_gry"
          style={{
            height: 45,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0 20px",
          }}
        >
          <div style={{ flex: 1 }}>
            <a href="/product" className="item">
              <span className="single_line txt_16 color_gry_a">Product</span>
            </a>
          </div>
          <img alt="" style={{ width: 10 }} src={ic_open} />
        </div>

        {/* Rules
        <div
          className="btn_hvr_gry"
          style={{
            height: 45,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0 20px",
          }}
        >
          <div style={{ flex: 1 }}>
            <a href="/rules" className="item">
              <span className="single_line txt_16 color_gry_a">Rules</span>
            </a>
          </div>
          <img alt="" style={{ width: 10 }} src={ic_open} />
        </div> */}
      </div>
    </animated.div>
  );
});

function logOut() {
  removeStorage(STORAGE_DT_ADMIN);
  window.open("/admin", "_self");
}

export function showNavigation(title) {
  let m_user = getJSONStorage(STORAGE_DT_ADMIN);
  return (
    <div className="admin_nav_ctn ntv_bg_b crs_default">
      <div
        style={{
          display: "flex",
          height: "100%",
          flexDirection: "row",
          alignItems: "center",
          padding: "0 20px",
        }}
      >
        <div style={{ flex: 1 }}>
          <span className="txt_16 color_wht_a">{title}</span>
        </div>
        <div>
          <span className="txt_16 color_wht_a">
            {m_user === null ? "User Not Found" : m_user.nm_admin}
          </span>
        </div>
        <div
          onClick={logOut}
          style={{ width: 32, height: 32, borderRadius: "50%", marginLeft: 20 }}
          className="ntv_bg_a crs_pointer view_center"
        >
          <img src={ic_logout} alt="" style={{ width: 16 }} />
        </div>
      </div>
    </div>
  );
}
