import { useImperativeHandle, forwardRef, useState, useRef, useEffect } from 'react';

import { isNullData } from '../utilities/Data';

export const TextInput = forwardRef((props, ref) => {
  const [text, setText] = useState('');
  const _onChangeText = useRef(null);

  var outer_style = isNullData(props.outer_style) ? {} : props.outer_style;
  var inner_style = isNullData(props.inner_style) ? {} : props.inner_style;
  var max_length = isNullData(props.max_length) ? 500 : props.max_length;
  var placeholder = isNullData(props.placeholder) ? '' : props.placeholder;
  var input_type = isNullData(props.input_type) ? 'text' : props.input_type;
  var is_active = isNullData(props.is_active) ? true : props.is_active;

  useEffect(() => {
    // componentWillUnmount
    return () => {
      if (_onChangeText.current !== null) { clearTimeout(_onChangeText.current); }
    }
  }, [])

  useImperativeHandle(ref, () => ({
    getText() {
      return text;
    }
  }));

  function updateText(text) {
    if (!isNullData(props.changeCallback)) {
      if (_onChangeText.current !== null) { clearTimeout(_onChangeText.current); }
      _onChangeText.current = setTimeout(() => {
        props.changeCallback(text);
      }, 1000);
    }
    setText(text);
  }

  return (
    <div className='ctn_input' style={outer_style}>
      <input
        maxLength={max_length}
        value={text}
        type={input_type}
        placeholder={placeholder}
        className={`${is_active ? '' : 'in_deactive'} color_gry_a txt_14`}
        style={inner_style}
        onChange={(e) => updateText(e.target.value)}
      />
    </div>
  );
})
