import React, {
  Component,
  useImperativeHandle,
  forwardRef,
  useState,
} from "react";
import { withRouter, Link } from "react-router-dom";

import { ErrorDialog } from "../native/Error";

import { PageNavigation, showNavigation } from "../native/AdminNavigation";
import { showToolbar } from "../native/Toolbar";
import { LoadingDialog } from "../native/Loading";
import ViewTable from "../native/Table";
import { onRequest, onRequestDownload } from "../utilities/Request";

//Icon
import ic_menu from "../images/menu_a.png";
import ic_search from "../images/search_a.png";
import ic_add from "../images/add_a.png";
import ic_download from "../images/download.png";
import { getJSONStorage, STORAGE_DT_ADMIN } from "../utilities/Storage";
import { Switch } from "@mui/material";

const ModalAdd = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const [update, setUpdate] = useState(false);
  const [adress, setAdress] = useState(false);
  const [noHp, setNoHp] = useState(false);
  const [freelancerName, setFreelancerName] = useState(false);
  const [idFreelancer, setidFreelancer] = useState("");
  const [subdist, setSubdist] = useState("");
  const [region, setRegion] = useState([]);

  useImperativeHandle(ref, () => ({
    updateShow(is_show, is_update, data) {
      handleDataRegion();

      setShow(is_show);
      if (data != null) {
        setUpdate(is_update);
        setAdress(data.address_member);
        setNoHp(data.user_no_hp);
        setFreelancerName(data.member_nm);
        setidFreelancer(data.member_id);
      } else {
        setUpdate(false);
        setAdress("");
        setNoHp("");
        setFreelancerName("");
        setidFreelancer("");
      }
    },
  }));

  if (!show) {
    return null;
  }

  function handleDataRegion() {
    props.context.loading_dialog_ref.current.updateShow(true);
    let body = {};
    onRequest(
      "/show/getRegion",
      body,
      (res_success) => {
        console.log(res_success.result);

        props.context.loading_dialog_ref.current.updateShow(false);
        setRegion(res_success.result === null ? [] : res_success.result);
      },

      // On Fail Login and Reading data !
      (res_fail) => {
        props.context.loading_dialog_ref.current.updateShow(false);
        props.context.error_dialog_ref.current.updateError(res_fail);
      }
    );
  }

  const handleSubmit = (event) => {
    //Prevent page reload
    event.preventDefault();

    props.context.loading_dialog_ref.current.updateShow(true);

    if (!update) {
      let body = {
        // Adjust with api retail save
        member_no_hp: noHp,
        member_nm: freelancerName,
        address_member: adress,
        region_id: subdist,
      };

      onRequest(
        "/post/add_member",
        body,
        (res_success) => {
          props.context.loading_dialog_ref.current.updateShow(false);

          setShow(false);
          props.saveChange();
        },
        (res_fail) => {
          props.context.loading_dialog_ref.current.updateShow(false);
          props.context.error_dialog_ref.current.updateError(res_fail);
        }
      );
    } else {
      let body = {
        // Adjust with api retail edit
        member_id: idFreelancer,
        user_no_hp: noHp,
        member_nm: freelancerName,
        address_member: adress,
        region_id: subdist.region_id,
      };

      onRequest(
        "/update/member",
        body,
        (res_success) => {
          props.context.loading_dialog_ref.current.updateShow(false);

          setShow(false);
          props.saveChange();
        },
        (res_fail) => {
          props.context.loading_dialog_ref.current.updateShow(false);
          props.context.error_dialog_ref.current.updateError(res_fail);
        }
      );
    }
  };

  return (
    <div
      className="modal fade show"
      style={{ display: "block", background: "#00000050", zIndex: 1 }}
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              {update === false ? (
                <span>Input Data Freelancer</span>
              ) : (
                <span>Update Data Freelancer</span>
              )}
            </h1>
            <button
              onClick={() => setShow(false)}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="form">
              {/* <form> */}
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="member_nm" className="col-form-label">
                    Freelance Name:
                  </label>
                  <input
                    type="text"
                    name="member_nm"
                    className="form-control"
                    id="member_nm"
                    value={freelancerName}
                    onChange={(e) => setFreelancerName(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="member_no_hp" className="col-form-label">
                    Phone Number:
                  </label>
                  <input
                    className="form-control"
                    input="member_no_hp"
                    id="member_no_hp"
                    value={noHp}
                    onChange={(e) => setNoHp(e.target.value)}
                  ></input>
                </div>
                <div className="mb-3">
                  <label htmlFor="address_member" className="col-form-label">
                    Address Freelance:
                  </label>
                  <input
                    className="form-control"
                    input="address_member"
                    id="address_member"
                    value={adress}
                    onChange={(e) => setAdress(e.target.value)}
                  ></input>
                </div>
                <div className="mb-3">
                  <label htmlFor="address_member" className="col-form-label">
                    Region:
                  </label>
                  <select
                    name="region"
                    id="region"
                    onChange={(e) => {
                      setSubdist(e.target.value);
                      console.log(e.target.value);
                    }}
                    className="form-select p-2"
                    value={subdist}
                  >
                    <option value="">Pilih Region</option>
                    {region.map((kode, key) => (
                      <option
                        style={{ fontWeight: "normal", minHeight: "20px" }}
                        key={key}
                        value={kode.region_id}
                      >
                        <h1>{kode.region}</h1>
                      </option>
                    ))}
                  </select>
                </div>
                <div className="modal-footer">
                  <button
                    onClick={() => setShow(false)}
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <input type="submit" className="btn btn-primary" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

class Freelance extends Component {
  constructor(props) {
    super(props);
    this.loading_dialog_ref = React.createRef(null);
    this.filter_ref = React.createRef(null);
    this.navigation_ref = React.createRef(null);
    this.table_ref = React.createRef(null);
    this.error_dialog_ref = React.createRef(null);
    this.modal_add_ref = React.createRef(null);

    this.filtered_search = null;
    this.list_data = [];
    this.download_data = [];
    this.pagination = null; // FOR PAGINATION
    this.user = getJSONStorage(STORAGE_DT_ADMIN);
    this.state = {
      toolbar: [
        {
          key: "menu",
          icon: ic_menu,
          text: "Menu",
        },
        {
          key: "search",
          icon: ic_search,
          text: "Search",
        },
        // Remove add for rn
        {
          key: "add",
          icon: ic_add,
          text: "Add",
        },
        {
          key: "download",
          icon: ic_download,
          text: "Download",
        },
      ],
      filter: [
        {
          type: "input_free_text",
          key: "a.member_id",
          value: "",
          active: false,
          label: "ID Freelance",
          class_bg: "blu_bg_b",
        },
        {
          type: "input_free_text",
          key: "a.member_nm",
          value: "",
          active: false,
          label: "Nama Freelance",
          class_bg: "pnk_bg_a",
        },
        {
          type: "input_text_phone_number",
          key: "a.user_no_hp",
          value: "",
          active: true,
          label: "Nomor HP",
          class_bg: "pnk_bg_a",
        },
      ],
      status_member: [],
      textActivation: "",
    };
  }

  componentDidMount() {
    this.getCurrentStatusMember(false);
  }

  getCurrentStatusMember(is_next) {
    this.loading_dialog_ref.current.updateShow(true);
    let body = {};
    onRequest(
      "/setting/get_status_member",
      body,
      (res_success) => {
        // FOR PAGINATION & TABLE
        this.setState({
          status_member: res_success.result,
          textActivation: res_success.result.includes(this.user.id_client)
            ? "Apakah Anda yakin fitur Add Member ingin di non-aktif kan?"
            : "Apakah Anda yakin fitur Add Member ingin di aktif kan?",
        });
        this.requestData(is_next);
      },

      // On Fail Login and Reading data !
      (res_fail) => {
        this.loading_dialog_ref.current.updateShow(false);
        this.error_dialog_ref.current.updateError(res_fail);
      }
    );
  }

  requestData(is_next) {
    this.loading_dialog_ref.current.updateShow(true);
    let body = {};
    if (this.filtered_search !== null) {
      body.filter = this.filtered_search;
    }
    if (is_next && this.pagination !== null) {
      body.pagination = this.pagination;
    }
    onRequest(
      "/show/member",
      body,
      (res_success) => {
        // FOR PAGINATION & TABLE
        let new_data = [];
        if (!is_next) {
          new_data = res_success.result.list_member;
        } else {
          new_data = this.list_data.concat(res_success.result.list_member);
        }
        this.list_data = new_data;
        this.table_ref.current.setData(this.list_data);
        this.pagination = res_success.result.pagination;
        // FOR PAGINATION & TABLE
        this.loading_dialog_ref.current.updateShow(false);
      },

      // On Fail Login and Reading data !
      (res_fail) => {
        this.loading_dialog_ref.current.updateShow(false);
        this.error_dialog_ref.current.updateError(res_fail);
      }
    );
  }

  downloadData() {
    this.loading_dialog_ref.current.updateShow(true);
    let body = {};
    onRequestDownload(
      "/export/member",
      body,
      (res_success) => {
        let url_data = [];
        this.loading_dialog_ref.current.updateShow(false);

        // Create blob link to download
        url_data = window.URL.createObjectURL(new Blob([url_data]));
        const url = res_success.result;

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `FileName.xlsx`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      },

      // On Fail Login and Reading data !
      (res_fail) => {
        this.loading_dialog_ref.current.updateShow(false);
        this.error_dialog_ref.current.updateError(res_fail);
      }
    );
  }

  actionToolbar(data) {
    if (data.key === "add") {
      this.modal_add_ref.current.updateShow(true);
    }
    if (data.key === "download") {
      this.downloadData();
    }
  }

  activateAddMemberConfirmation() {
    if (window.confirm(this.state.textActivation) == true) {
      this.activateAddMember();
    } else {
      console.log("Haha");
    }
  }

  activateAddMember() {
    try {
      let body = {
        status_update: this.state.status_member.includes(this.user.id_client)
          ? "deactive"
          : "active",
      };

      onRequest(
        "/setting/add_member_web",
        body,
        (res_success) => {
          console.log(res_success.result);
          this.getCurrentStatusMember(false);
        },

        // On Fail Login and Reading data !
        (res_fail) => {
          this.loading_dialog_ref.current.updateShow(false);
          //this.error_dialog_ref.current.updateError(res_fail);
        }
      );
    } catch (error) {
      alert(error);
    }
  }

  updateData(index, change) {
    const changeSwitch = (this.list_data[index].is_block = change);
    const id = this.list_data[index].member_id;
    this.list_data[index].is_block = change;

    this.table_ref.current.setData(this.list_data);

    let body = {
      member_id: id,
      is_block: changeSwitch,
    };

    onRequest(
      "/update/block_member",
      body,
      (res_success) => {
        this.loading_dialog_ref.current.updateShow(true);
        this.list_data = res_success.result.list_member;
        this.table_ref.current.setData(this.list_data);
      },

      // On Fail Login and Reading data !
      (res_fail) => {
        this.loading_dialog_ref.current.updateShow(false);
        //this.error_dialog_ref.current.updateError(res_fail);
      }
    );
  }

  getViewHeader() {
    return (
      <tr className="color_wht_a gry_bg_a txt_14">
        <th>ID Freelance</th>
        <th>Nama Freelance</th>
        <th>No HP</th>
        <th>Address</th>
        <th>Point</th>
        <th>Register Date</th>
        <th>Isblock</th>
        <th>Register By</th>
        <th>Edit</th>
      </tr>
    );
  }

  showEdit(data) {
    this.modal_add_ref.current.updateShow(true, true, data);
  }

  getViewBody(index, data) {
    const checkBox = data.is_block === true ? true : false;

    return (
      <tr key={data.member_id} className="color_gry_a txt_14 single_line">
        <td>{data.member_id}</td>
        <td>{data.member_nm}</td>
        <td>{data.user_no_hp}</td>
        <td>{data.address_member}</td>
        <td>{data.value_point}</td>
        <td>{data.register_date}</td>
        <td>
          <div className="form-check form-switch">
            <input
              id="switch"
              role="switch"
              checked={checkBox}
              type="checkbox"
              className="form-check-input"
              onChange={(e) => this.updateData(index, e.target.checked)}
            />
          </div>
        </td>
        <td>{data.register_by}</td>
        <td>
          <button
            className="btn btn-warning"
            type="Button"
            onClick={this.showEdit.bind(this, data)}
          >
            Edit
          </button>
        </td>
      </tr>
    );
  }

  onLastPageSelected() {
    if (this.pagination.is_more) {
      this.requestData(true);
    }
  }

  onFilter(filter) {
    this.filtered_search = filter;
    this.requestData(false);
  }

  saveChange() {
    this.filtered_search = null;
    this.requestData(false);
  }

  render() {
    return (
      <div className="app" style={{ display: "flex", flexDirection: "column" }}>
        {showNavigation("LIST FREELANCER")}
        <div
          className="body"
          style={{ display: "flex", flex: 1, flexDirection: "row" }}
        >
          <PageNavigation ref={this.navigation_ref} />
          <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <div
              className="adm_tbr_ctn"
              style={{ display: "flex", flexDirection: "row" }}
            >
              {showToolbar(this, this.actionToolbar.bind(this))}
              {/* { getViewAction(context, callback) } */}
              <div
                className="view_center"
                style={{
                  padding: "0 10px",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                {/* disini switch nya */}
                <div style={{ marginTop: -5 }}>
                  <Switch
                    size="small"
                    checked={this.state.status_member.includes(
                      this.user.id_client
                    )}
                    onChange={() => this.activateAddMemberConfirmation()}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>
                <div style={{ marginTop: -5 }}>
                  <span className="color_gry_a txt_12">Aktivasi</span>
                </div>
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <ViewTable
                tableRef={this.table_ref}
                filterRef={this.filter_ref}
                dataFilter={this.state.filter}
                headerView={this.getViewHeader.bind(this)}
                bodyView={this.getViewBody.bind(this)}
                searchContent={[
                  "member_id",
                  "member_nm",
                  "user_no_hp",
                  "register_date",
                  "register_by",
                  "address_member",
                ]}
                onLastPage={this.onLastPageSelected.bind(this)}
                onFilter={this.onFilter.bind(this)}
              />
            </div>
          </div>
        </div>
        <ModalAdd
          ref={this.modal_add_ref}
          context={this}
          saveChange={this.saveChange.bind(this)}
        />
        <ErrorDialog ref={this.error_dialog_ref} />
        <LoadingDialog ref={this.loading_dialog_ref} />
      </div>
    );
  }
}

export default withRouter(Freelance);
