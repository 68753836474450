import {
  Component,
  useImperativeHandle,
  forwardRef,
  createRef,
  useState,
} from "react";
import { useSpring, animated } from "@react-spring/web";

import { TextInput } from "./Input";
import { Button } from "./Button";
import { Dropdown } from "./Dropdown";
import { isNullData } from "../utilities/Data";
import styles from "../styles/Styles";

import ic_cancel_a from "../images/cancel_a.png";
import ic_cancel_b from "../images/cancel_b.png";

const { Document } = require("flexsearch");

const FILTER_WIDTH = 260;

const Filter = forwardRef((props, ref) => {
  const [show_filter, setFilter] = useState(true);
  const [ctn_style, ctn_anim] = useSpring(() => ({ width: FILTER_WIDTH }), []);

  const [data_filter, setDataFilter] = useState(setFilterRef());

  useImperativeHandle(ref, () => ({
    openFilter() {
      updateFilter();
    },
  }));

  function setFilterRef() {
    let m_filter = props.dataFilter;
    for (let i = 0; i < m_filter.length; i++) {
      if (isNullData(m_filter[i].ref_input)) {
        m_filter[i].ref_input = createRef();
      }
    }
    return m_filter;
  }

  function updateFilter() {
    let c_sts = !show_filter;
    if (c_sts) {
      ctn_anim.start({
        to: [{ width: FILTER_WIDTH }],
      });
    } else {
      ctn_anim.start({
        to: [{ width: 0 }],
      });
    }
    setFilter(c_sts);
  }

  function updateActive(idx, is_active) {
    let temp = [...data_filter];
    temp[idx].active = is_active;
    setDataFilter(temp);
  }

  function getTypeView(idx) {
    if (
      ["input_text", "input_free_text", "input_text_phone_number"].includes(
        data_filter[idx].type
      )
    ) {
      return (
        <TextInput
          ref={data_filter[idx].ref_input}
          inner_style={styles.brd_bt_gry_a}
        />
      );
    } else if (data_filter[idx].type === "single_date") {
      return (
        <TextInput
          input_type='date'
          ref={data_filter[idx].ref_input}
          inner_style={styles.brd_bt_gry_a}
        />
      );
    } else if (data_filter[idx].type === "dropdown") {
      return (
        <Dropdown
          ref={data_filter[idx].ref_input}
          init_selected={data_filter[idx].selected}
          inner_style={styles.brd_bt_gry_a}
          choice={data_filter[idx].data}
        />
      );
    } else {
      return null;
    }
  }

  function onFilterClicked() {
    if (!isNullData(props.onFilter)) {
      let m_filter = [];
      for (let i = 0; i < data_filter.length; i++) {
        if (data_filter[i].active) {
          if (
            [
              "input_free_text",
              "input_text",
              "input_text_phone_number",
            ].includes(data_filter[i].type)
          ) {
            m_filter.push({
              type: data_filter[i].type,
              key: data_filter[i].key,
              value: data_filter[i].ref_input.current.getText(),
            });
          } else if (data_filter[i].type === 'single_date' && !isNullData(data_filter[i].ref_input.current.getText())) {
            m_filter.push({
              type: data_filter[i].type,
              key: data_filter[i].key,
              value: data_filter[i].ref_input.current.getText()
            });
          } else if (data_filter[i].type === 'dropdown') {
            m_filter.push({
              type : data_filter[i].type,
              key : data_filter[i].key,
              selected : data_filter[i].ref_input.current.getSelected()
            });
          }
        }
      }
      if (m_filter.length > 0) {
        props.onFilter(m_filter);
      }
    }
  }

  function getFilterView() {
    let view = [],
      view_tag = [];
    for (let i = 0; i < data_filter.length; i++) {
      view_tag.push(
        <div
          key={`tag-${data_filter[i].key}`}
          onClick={() => updateActive(i, true)}
          className={`btn_hvr_gry single_line view_center ${data_filter[i].class_bg}`}
          style={{
            padding: "5px 10px",
            margin: "10px 10px 0 0",
            borderRadius: 8,
            flexShrink: 0,
            flexDirection: "row",
          }}
        >
          <span className="txt_12 color_wht_a">{data_filter[i].label}</span>
        </div>
      );
      if (data_filter[i].active) {
        view.push(
          <div key={data_filter[i].key} style={{ padding: "10px 20px 0" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                className="single_line"
                style={{ flex: 1, minWidth: 150, paddingRight: 10 }}
              >
                <span className="txt_14 color_gry_a">
                  {data_filter[i].label}
                </span>
              </div>

              <div
                onClick={() => updateActive(i, false)}
                className="crs_pointer view_center"
                style={{ width: 20, height: 20 }}
              >
                <img alt="" src={ic_cancel_b} style={{ width: 10 }} />
              </div>
            </div>
            {getTypeView(i)}
          </div>
        );
      }
    }
    return (
      <div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            padding: "10px 20px",
          }}
        >
          {view_tag}
        </div>
        {view}
        {view.length > 0 ? (
          <Button
            text="FILTER"
            outer_style={{ padding: "0 20px", margin: "20px 0" }}
            onPressed={onFilterClicked}
          />
        ) : null}
      </div>
    );
  }

  return (
    <animated.div style={{ ...ctn_style, ...{ overflow: "hidden" } }}>
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <div
          className="ntv_bg_a"
          style={{
            height: 40,
            display: "flex",
            alignItems: "center",
            paddingLeft: 20,
          }}
        >
          <div style={{ flex: 1 }}>
            <span className="single_line txt_14 color_wht_a">
              SEARCH FILTER
            </span>
          </div>
          <div
            onClick={() => updateFilter()}
            className="view_center crs_pointer"
            style={{ padding: "0 20px", height: "100%" }}
          >
            <img src={ic_cancel_a} style={{ width: 16 }} alt="" />
          </div>
        </div>

        <div
          style={{
            flex: 1,
            position: "relative",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <div style={{ position: "absolute", width: "100%", top: 0, left: 0 }}>
            {getFilterView()}
          </div>
        </div>
      </div>
    </animated.div>
  );
});

class Table extends Component {
  constructor(props) {
    super(props);
    this._index = null;
    this.max_page_choice = [
      { desc: "100", key: 100 },
      { desc: "150", key: 150 },
      { desc: "200", key: 200 },
      { desc: "250", key: 250 },
    ];
    this.state = {
      page: 0,
      max_page: 0,
      max_view: 100,
      search_idx: null,
      data: [],
    };
  }

  setData(data) {
    if (!isNullData(this.props.searchContent)) {
      this._index = new Document({
        id: "id",
        tokenize: "full",
        index: ["content"],
      });

      let i, j, content;
      for (i = 0; i < data.length; i++) {
        content = "";
        for (j = 0; j < this.props.searchContent.length; j++) {
          content = content.concat(" ", data[i][this.props.searchContent[j]]);
        }
        this._index.add({
          id: i,
          content: content,
        });
      }
    }
    this.setState({
      data: data,
      page: 0,
      max_page: Math.ceil(data.length / this.state.max_view),
    });
  }

  onSearchText(text) {
    if (text.length > 0 && this._index !== null) {
      let search = this._index.search(text, 1000);
      if (search.length > 0) {
        this.setState({
          search_idx: search[0].result,
          page: 0,
          max_page: Math.ceil(search[0].result.length / this.state.max_view),
        });
      } else {
        // NOT FOUND ANY RECORD
        this.setState({ search_idx: [], page: 0, max_page: 0 });
      }
    } else {
      // RESET SEARCH INDEX
      this.setState({
        search_idx: null,
        page: 0,
        max_page: Math.ceil(this.state.data.length / this.state.max_view),
      });
    }
  }

  getViewBody() {
    let view = [],
      c_view = 0,
      data_idx = this.state.page * this.state.max_view;
    if (this.state.search_idx !== null) {
      // WHEN ON SEARCH
      while (
        c_view < this.state.max_view &&
        data_idx < this.state.search_idx.length
      ) {
        view.push(
          this.props.bodyView(
            this.state.search_idx[data_idx],
            this.state.data[this.state.search_idx[data_idx]]
          )
        );
        data_idx++;
        c_view++;
      }
    } else {
      // NON SEARCH
      while (
        c_view < this.state.max_view &&
        data_idx < this.state.data.length
      ) {
        view.push(this.props.bodyView(data_idx, this.state.data[data_idx]));
        data_idx++;
        c_view++;
      }
    }
    if (c_view === 0) {
      view.push(
        <tr key="not_found" className="color_gry_a txt_14">
          <td colSpan={100}>No record data found...</td>
        </tr>
      );
    }
    return view;
  }

  changePage(page) {
    this.setState({ page: page }, () => {
      if (
        page === this.state.max_page - 1 &&
        !isNullData(this.props.onLastPage)
      ) {
        this.props.onLastPage();
      }
    });
  }

  getPagination() {
    let view = [],
      i;
    if (this.state.page > 2) {
      view.push(
        <div
          key={0}
          onClick={this.changePage.bind(this, 0)}
          className="btn_hvr_gry"
          style={{ padding: "4px 10px", borderRadius: "50%" }}
        >
          <span className="color_gry_a txt_16">1...</span>
        </div>
      );
    }
    for (
      i = this.state.page < 2 ? 0 : this.state.page - 2;
      i < this.state.page + 3 && i < this.state.max_page;
      i++
    ) {
      view.push(
        <div
          key={i}
          onClick={this.changePage.bind(this, i)}
          className={`btn_hvr_gry ${this.state.page === i ? "gry_bg_a" : ""}`}
          style={{ padding: "4px 10px", borderRadius: "50%" }}
        >
          <span className="color_gry_a txt_16">{i + 1}</span>
        </div>
      );
    }
    if (i !== this.state.max_page) {
      view.push(
        <div
          key={this.state.max_page}
          onClick={this.changePage.bind(this, this.state.max_page - 1)}
          className="btn_hvr_gry"
          style={{ padding: "4px 10px", borderRadius: "50%" }}
        >
          <span className="color_gry_a txt_16">...{this.state.max_page}</span>
        </div>
      );
    }
    return view;
  }

  changeMaxPage(choice) {
    if (this.state.search_idx === null) {
      // FOR NON SEARCH TABLE
      this.setState({
        page: 0,
        max_view: choice.key,
        max_page: Math.ceil(this.state.data.length / choice.key),
      });
    } else {
      // FOR SEARCH TABLE
      this.setState({
        page: 0,
        max_view: choice.key,
        max_page: Math.ceil(this.state.search_idx.length / choice.key),
      });
    }
  }

  render() {
    return (
      <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <div
          className="ntv_bg_b"
          style={{
            height: 40,
            display: "flex",
            alignItems: "center",
            padding: "0 20px",
          }}
        >
          <div className="single_line">
            <span className="txt_14 color_wht_a">LIST TABLE</span>
          </div>
        </div>
        <div style={{ flex: 1, position: "relative", overflow: "auto" }}>
          <div style={{ position: "absolute", top: 0, left: 0, padding: 20 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingBottom: 20,
              }}
            >
              <div style={{ paddingRight: 10 }}>
                <Dropdown
                  init_selected={{ desc: "100", key: 100 }}
                  outer_style={{ minWidth: 80, width: 80 }}
                  inner_style={styles.brd_bt_gry_a}
                  choice_outer_style={{ minWidth: 80 }}
                  choice={this.max_page_choice}
                  onChangeListener={this.changeMaxPage.bind(this)}
                />
              </div>
              <TextInput
                inner_style={{ ...styles.brd_gry_a, ...{ borderRadius: 5 } }}
                outer_style={{ minWidth: 190, width: "100%", maxWidth: 250 }}
                placeholder="Search data table..."
                changeCallback={this.onSearchText.bind(this)}
              />
            </div>
            <table style={{ borderCollapse: "collapse" }}>
              <thead style={{ top: "0", position: "sticky" }}>
                {isNullData(this.props.headerView)
                  ? null
                  : this.props.headerView()}
              </thead>
              <tbody>
                {isNullData(this.props.bodyView) ? null : this.getViewBody()}
              </tbody>
            </table>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                paddingTop: 20,
              }}
            >
              {this.getPagination()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default class ViewTable extends Component {
  render() {
    return (
      <div className="adm_tbl_ctn crs_default">
        <Filter
          ref={this.props.filterRef}
          dataFilter={this.props.dataFilter}
          onFilter={this.props.onFilter}
        />
        <Table
          ref={this.props.tableRef}
          headerView={this.props.headerView}
          bodyView={this.props.bodyView}
          searchContent={this.props.searchContent}
          onLastPage={this.props.onLastPage}
        />
      </div>
    );
  }
}
