import { STORAGE_DT_ADMIN, getJSONStorage } from "./Storage";
// import { ERR_NO_CONNECTION, ERR_INTERNAL_USER } from '../native/Error';

const ERR_NO_CONNECTION = 1,
  ERR_INTERNAL_USER = 2;

// const PATH_2 = "https://gpandroid.tokopintarmayora.com/web_spg";
const PATH = "https://gpapi.tokopintarmayora.com/web_spg";
// const PATH = "https://gpapi.bersama.co.id/web_spg";
// const PATH = 'http://localhost:1997/web_spg';
// const PATH = 'http://localhost:1995/web_spg';

export const APP_VERSION = "0.001";

function fetchWithTimeout(url, options, timeout) {
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) =>
      setTimeout(() => reject(new Error("no_connection")), timeout)
    ),
  ]);
}

export function onRequest(
  route,
  body,
  listener_success,
  listener_fail,
  id_client
) {
  if (listener_fail === undefined) {
    listener_fail = null;
  }

  let m_customer = getJSONStorage(STORAGE_DT_ADMIN);
  let id_client_master = id_client ? id_client : m_customer?.id_client;

  let headers = {
    version: APP_VERSION,
    "Content-Type": "application/json",
    retail: m_customer?.id_user,
    session: m_customer?.session,
    "id-client": id_client_master,
  };

  // if (m_customer !== null) {
  //   headers.retail = m_customer.id_user;
  //   headers.session = m_customer.session;
  //   headers["id-client"] = m_customer.id_client;
  // }

  // if (id_client && (!m_customer || (m_customer && m_customer.id_client !== id_client))) {
  //   headers['id-client'] = id_client;
  // }

  fetchWithTimeout(
    PATH + route,
    {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    },
    30000
  )
    .then((response) => response.json())
    .then((result) => {
      if (result.code === 1) {
        listener_success(result);
      } else {
        if (listener_fail !== null) {
          listener_fail(result);
        }
      }
    })
    .catch((error) => {
      if (listener_fail !== null) {
        let res_error = {};
        if (error.message === "no_connection") {
          // Specific for network error only
          res_error.message =
            "Tidak ada respon dari server, silahkan cek koneksi anda terlebih dahulu!";
          res_error.result = { action: ERR_NO_CONNECTION, message: "Refresh" };
        } else {
          // Internal server error
          res_error.message = `Message = ${error.message}.`;
          res_error.result = {
            action: ERR_INTERNAL_USER,
            message: "Laporkan Error",
          };
        }
        listener_fail(res_error);
      }
    });
}

export function onRequestDownload(
  route,
  body,
  listener_success,
  listener_fail
) {
  if (listener_fail === undefined) {
    listener_fail = null;
  }

  let headers = {
    version: APP_VERSION,
    "Content-Type": "application/json",
  };
  let m_customer = getJSONStorage(STORAGE_DT_ADMIN);
  if (m_customer !== null) {
    headers.retail = m_customer.id_user;
    headers.session = m_customer.session;
    headers["id-client"] = m_customer.id_client;
  }
  fetchWithTimeout(
    PATH + route,
    {
      method: "POST",
      headers: headers,
      responseType: "blob",
      body: JSON.stringify(body),
    },
    30000
  )
    .then((response) => response.json())
    .then((result) => {
      if (result.code === 1) {
        listener_success(result);
      } else {
        if (listener_fail !== null) {
          listener_fail(result);
        }
      }
    })
    .catch((error) => {
      if (listener_fail !== null) {
        let res_error = {};
        if (error.message === "no_connection") {
          // Specific for network error only
          res_error.message =
            "Tidak ada respon dari server, silahkan cek koneksi anda terlebih dahulu!";
          res_error.result = { action: ERR_NO_CONNECTION, message: "Refresh" };
        } else {
          // Internal server error
          res_error.message = `Message = ${error.message}.`;
          res_error.result = {
            action: ERR_INTERNAL_USER,
            message: "Laporkan Error",
          };
        }
        listener_fail(res_error);
      }
    });
}

export function onRequestForm(route, form, listener_success, listener_fail) {
  if (listener_fail === undefined) {
    listener_fail = null;
  }

  let headers = {
    version: APP_VERSION,
  };
  let m_customer = getJSONStorage(STORAGE_DT_ADMIN);
  if (m_customer !== null) {
    headers.retail = m_customer.id_user;
    headers.session = m_customer.session;
    headers["id-client"] = m_customer.id_client;
  }
  fetchWithTimeout(
    PATH + route,
    {
      method: "POST",
      headers: headers,
      body: form,
    },
    30000
  )
    .then((response) => response.json())
    .then((result) => {
      if (result.code === 1) {
        listener_success(result);
      } else {
        if (listener_fail !== null) {
          listener_fail(result);
        }
      }
    })
    .catch((error) => {
      if (listener_fail !== null) {
        let res_error = {};
        if (error.message === "no_connection") {
          // Specific for network error only
          res_error.message =
            "Tidak ada respon dari server, silahkan cek koneksi anda terlebih dahulu!";
          res_error.result = { action: ERR_NO_CONNECTION, message: "Refresh" };
        } else {
          // Internal server error
          res_error.message = `Message = ${error.message}.`;
          res_error.result = {
            action: ERR_INTERNAL_USER,
            message: "Laporkan Error",
          };
        }
        listener_fail(res_error);
      }
    });
}
