const m_style = {

  brd_gry_a : { border : '1px solid rgb(217, 217, 217)' },
  brd_bt_gry_a : { borderBottom : '1px solid rgb(217, 217, 217)' },

  mrd_bg_a : { background : '#c1aba6' },

  blu_bg_a : { background : '#6667ab' },
  blu_bg_b : { background : '#a4e9d5' },

  pnk_bg_a : { background : '#ed254e' },

}

export default m_style;
