import { useImperativeHandle, forwardRef, useState, useRef, useEffect } from 'react';

import { isNullData } from '../utilities/Data';

import ic_down from '../images/down_arrow_a.png';

export const Dropdown = forwardRef((props, ref) => {
  var init_selected = isNullData(props.init_selected) ? { desc : 'Choose here...', key : null } : props.init_selected;
  var choice = isNullData(props.choice) ? [] : props.choice;

  const [selected, setSelected] = useState(init_selected);

  const refChoice = useRef(null);
  const refSelected = useRef(null);

  var outer_style = isNullData(props.outer_style) ? {} : props.outer_style;
  var inner_style = isNullData(props.inner_style) ? {} : props.inner_style;
  var choice_outer_style = isNullData(props.choice_outer_style) ? {} : props.choice_outer_style;

  useImperativeHandle(ref, () => ({
    getSelected() {
      return selected;
    }
  }));

  function handleClickOutside(event) {
    if (refChoice.current && !refChoice.current.contains(event.target)) {
      if (!refSelected.current.contains(event.target)) {
        refChoice.current.classList.add('disable_view');
        refSelected.current.classList.remove('foc_dropdown');
      } else {
        refChoice.current.classList.toggle('disable_view');
        refSelected.current.classList.toggle('foc_dropdown');
      }
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  function onChoice(choice) {
    if (choice.key !== selected.key) {
      setSelected(choice);
      if (!isNullData(props.onChangeListener)) {
        props.onChangeListener(choice);
      }
    }
    refChoice.current.classList.add('disable_view');
    refSelected.current.classList.remove('foc_dropdown');
  }

  function showChoice() {
    let view = [];
    for (let i = 0; i < choice.length; i++) {
      view.push(
        <div key={choice[i].key} className='wht_bg_a' style={{minHeight : 40, display : 'flex'}}>
          <div onClick={() => onChoice(choice[i])} className='wht_bg_a btn_hvr_gry' style={{flex : 1, display : 'flex', alignItems : 'center', padding : 10}}><span className='txt_14 color_gry_a'>{choice[i].desc}</span></div>
        </div>
      );
    }
    if (view.length > 0) {
      return (
        <div ref={refChoice} className='disable_view' style={{...{position : 'absolute', top : 40, left : 0, width : '100%', minWidth : 150}, ...choice_outer_style}}>
          <div className='ctn_choice_dropdown'>
            { view }
          </div>
        </div>
      );
    } else { return null; }
  }

  return (
    <div style={{position : 'relative'}}>
      <div className='ctn_dropdown' style={outer_style}>
        <div ref={refSelected} className='crs_pointer chd_dropdown' style={inner_style}>
          <div style={{display : 'flex', flexDirection : 'row', alignItems : 'center', padding : '0 10px', height : '100%'}}>
            <div className='single_line' style={{flex : 1, paddingRight : 10}}><span className={`txt_14 ${selected.key === null ? 'color_gry_b' : 'color_gry_a'}`}>{selected.desc}</span></div>
            <img alt='' style={{width:10, marginLeft : 10}} src={ic_down}/>
          </div>
        </div>
      </div>

      { showChoice() }
    </div>
  );

})
