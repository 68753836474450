export const STORAGE_DT_ADMIN = 'spg_admin';

export function getJSONStorage(key) {
  try {
    let m_data = getStringStorage(key);
    if (m_data !== null) {
      return JSON.parse(m_data);
    } else { return null; }
  } catch (e) {
    return null;
  }
}

export function getStringStorage(key) {
  var m_data = localStorage.getItem(key);
  if (m_data === null) {
    return null;
  } else {
    // WHEN STORAGE DATA IS EXIST THEN RETURN STRING DATA
    return m_data;
  }
}

export function saveStorage(key, data) {
  if (data === undefined) { data = null; }
  if (data !== null) {
    localStorage.setItem(key, data);
  }
}

export function removeStorage(key) {
  localStorage.removeItem(key);
}
