import { useState, useImperativeHandle, forwardRef } from 'react';
import { isNullData } from '../utilities/Data';
import { Button } from './Button';
import { STORAGE_DT_ADMIN, removeStorage } from '../utilities/Storage';

import ic_sorry from '../images/sorry_a.png';

export const ERR_NO_CONNECTION = 1, ERR_EMPTY_FIELD = 2, ERR_INTERNAL_USER = 3,
  ERR_REGULAR = 4, ERR_GO_BACK = 5, ERR_REGULAR_FUNC = 6, ERR_SESSION = 7, ERR_NO_MEMBER = 8,
  ERR_PRESENCE = 9, ERR_NO_WHOLESALER = 10;

function getImageError(error) {
  let icon = ic_sorry;
  if (!isNullData(error.result)) {
    switch (error.result.action) {
      default:
        break;
    }
  }
  return (
    <div className='view_center' style={{padding : '20px 0'}}>
      <img alt='' style={{width : 100, height : 100}} src={icon}/>
    </div>
  );
}

function getTitleError(error) {
  let title = 'Oops!';
  if (!isNullData(error.result)) {
    switch (error.result.action) {
      case ERR_NO_CONNECTION: // ERROR NO CONNECTION
        title = 'Koneksi Lemah';
        break;
      case ERR_EMPTY_FIELD: // ERROR EMPTY FIELD
        title = 'Field Kosong';
        break;
      case ERR_INTERNAL_USER:
        title = 'Fatal Error';
        break;
      case ERR_SESSION:
        title = 'Session Habis';
        break;
      case ERR_NO_MEMBER:
        title = 'Pelanggan Baru';
        break;
      case ERR_PRESENCE:
        title = 'Belum Absen';
        break;
      case ERR_NO_WHOLESALER:
        title = 'Grosir Kosong';
        break;
      default:
        break;
    }
  }
  return <div className='view_center'><span className='txt_20 color_gry_a' style={{textAlign : 'center'}}>{title}</span></div>
}

async function getErrorAction(ref, error) {
  switch (error.result.action) {
    case ERR_REGULAR_FUNC:
      ref.current.updateShow(false);
      if (isNullData(error.result._func)) { error.result._func = null; }
      if (error.result._func !== null) { error.result._func(); }
      break;
    case ERR_SESSION:
      removeStorage(STORAGE_DT_ADMIN);
      window.open('/admin', '_self');
      break;
    default:
      ref.current.updateShow(false);
      break;
  }
}

export const ErrorDialog = forwardRef((props, ref) => {

  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);

  useImperativeHandle(ref, () => ({
    updateError(m_error) {
      setError(m_error);
      setShow(true);
    },
    updateShow(is_show) {
      setShow(is_show);
    }
  }));

  if (!show || error === null) { return null; }

  return (
    <div onClick={() => setShow(false)} style={m_style.root_dialog} >
      <div onClick={(e) => e.stopPropagation()} style={m_style.dialog_container}>
        <div style={{flex : 1, display : 'flex', flexDirection : 'column', padding : 20}}>
          { getTitleError(error) }
          <div className='color_gry_a txt_14 crs_default' style={{margin : '15px 0 0 0', textAlign : 'center', wordWrap : 'break-word'}}>{error.message}</div>
          { getImageError(error) }
          <Button text={isNullData(error.result) ? 'Tutup' : error.result.message} onPressed={() => {error.result === null ? setShow(false) : getErrorAction(ref, error)}}/>
        </div>
      </div>
    </div>
  );

})

const m_style = {
  root_dialog : {
    display : 'flex',
    flexDirection : 'column',
    position : 'fixed',
    overflow : 'auto',
    zIndex : 4,
    top : 0, left : 0,
    width : '100%',
    height : '100%',
    backgroundColor : 'rgba(0, 0, 0, 0.5)'
  },
  dialog_container : {
    display : 'flex',
    flexDirection : 'column',
    width : '80%',
    maxWidth : 300,
    backgroundColor : 'white',
    borderRadius : 10,
    margin : 'auto'
  }
}
