import { isNullData } from '../utilities/Data';

export const Button = (props) => {

  var text = isNullData(props.text) ? '' : props.text;
  var link = isNullData(props.link) ? '' : props.link;
  var outer_style = isNullData(props.outer_style) ? {} : props.outer_style;
  var inner_style = isNullData(props.inner_style) ? {} : props.inner_style;
  var bg_class = isNullData(props.bg_class) ? 'blu_bg_a' : props.bg_class;
  var disabled = isNullData(props.disabled) ? false : props.disabled;

  function onClickEvent() {
    if (!disabled && !isNullData(props.onPressed)) {
      props.onPressed();
    }
  }

  return (
    <div className='ctn_button' style={outer_style}>
      <div onClick={() => onClickEvent()} disabled={disabled} className={`chd_button view_center ${!disabled ? 'btn_hvr_gry' : 'crs_default'} ${bg_class}`} style={inner_style}>
        <span className='single_line txt_14 color_wht_a' href={link}>{text}</span>
      </div>
    </div>
  );

}
