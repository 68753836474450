import { isNullData } from '../utilities/Data';

export const KEY_SEARCH = 'search', KEY_MENU = 'menu';

function onClicked(data, callback) {
  switch (data.key) {
    case KEY_SEARCH:
      this.filter_ref.current.openFilter();
      break;
    case KEY_MENU:
      this.navigation_ref.current.openNavigation();
      break;
    default:
      break;
  }
  if (!isNullData(callback)) { callback(data); }
}

function getViewAction(context, callback) {
  let view = [], data = context.state.toolbar;
  for (let i = 0; i < Object.keys(data).length; i++) {
    if (data[i].key === "add" && context.state.status_member ) {
      {context.state.status_member.includes(context.user.id_client) && (
        view.push(
          <div onClick={onClicked.bind(context, data[i], callback)} key={data[i].key} className='btn_hvr_gry adm_tbr_act'>
            <div className='view_center' style={{padding: '0 10px', flexDirection : 'column', height : '100%'}}>
              <img alt='' src={data[i].icon} style={{height : 15, minHeight : 15}}/>
              <div><span className='color_gry_a txt_12'>{data[i].text}</span></div>
            </div>
          </div>
        )
      )}
    } else {
      view.push(
        <div onClick={onClicked.bind(context, data[i], callback)} key={data[i].key} className='btn_hvr_gry adm_tbr_act'>
          <div className='view_center' style={{padding: '0 10px', flexDirection : 'column', height : '100%'}}>
            <img alt='' src={data[i].icon} style={{height : 15, minHeight : 15}}/>
            <div><span className='color_gry_a txt_12'>{data[i].text}</span></div>
          </div>
        </div>
      );
    }
  }
  return view;
}

export function showToolbar(context, callback) {
  if (isNullData(context.state.toolbar)) { context.state.toolbar = []; }
  return (
    <div className='adm_tbr_ctn' style={{display : 'flex', flexDirection : 'row'}}>
      { getViewAction(context, callback) }
    </div>
  );
}
