import { useState, useImperativeHandle, forwardRef } from "react";

export const LoadingDialog = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);

  useImperativeHandle(ref, () => ({
    updateShow(is_show) {
      setShow(is_show);
    },
  }));

  if (show) {
    return (
      <div
        className="view_center"
        style={{
          zIndex: 99,
          position: "fixed",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.5)",
          top: 0,
          left: 0,
        }}
      >
        <div className="spinner-border text-danger" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    return null;
  }
});
