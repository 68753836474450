import { useSpring, animated } from '@react-spring/web';

import ic_loop from '../images/loop_a.png';

const AniFallback = () => {
  const props = useSpring({
    from: { x: 0 },
    to: { x: 360 },
    loop : true
  })

  return (
    <animated.div style={{ transform: props.x.to(value => `rotateZ(${value}deg)`) }}>
      <img alt='' src={ic_loop}/>
    </animated.div>
  )
}

export function showRouterFallback(context) {
  return (
    <div className='app view_center'>
      <AniFallback/>
    </div>
  );
}
